import { Component, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { HomeFacade } from "@app/store/home/home.facade";
import { FirebaseAuthService } from "@app/shared/services/firebase-auth.service";
import { AuthFacade } from "@app/shared/store/auth/auth.facade";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { EMPTY, Observable, Subscription, take } from "rxjs";
import { shareObservable } from "@app/helpers/rxjs-helper";
import { MatIconRegistry } from "@angular/material/icon";
import { ICON_LIST } from "@app/core/models/icon-list";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from 'moment';
import 'moment/locale/ar';
import { ConfigService } from "@app/shared/services/config.service";
import { UserSettingsFacade } from "@app/shared/store/user-settings/user-settings.facade";
import { environment} from "@environments/environment";
import { ZohoService } from "@app/shared/services/zoho.service";
import { PlatformConfigFacade } from "@app/shared/store/platform-config/platform-config-facade";
import { ClarityService } from "@app/shared/services/clarity.service";
import { User } from "@app/shared/models/user/user";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [CommonModule],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'geoking_frontend';
    sideBarOpened = false;
    isSideBarVisible$: Observable<boolean> = this.homeFacade.selectIsSidebarVisible$;
    isOnBoarding$: Observable<boolean> = this.homeFacade.selectIsOnBoarding$;

    env = JSON.parse(JSON.stringify(environment));
    userStateSubscription$: Subscription = new Subscription();
    userSettingsSubscription$: Subscription = new Subscription();
    zohoSubscription$: Subscription = new Subscription();
    claritySubscription$: Subscription = new Subscription();
    isLoadingTheme = true;
    isLoggedInAsPlatformAdmin$: Observable<boolean> = new Observable<boolean>();
    userEmail$: Observable<string> = new Observable<string>();
    private clientUrl = this.env.clientUrl;


    constructor(private translateService: TranslateService,
                private sanitiser: DomSanitizer,
                private iconRegistry: MatIconRegistry,
                private authService: FirebaseAuthService,
                private renderer: Renderer2,
                private authFacade: AuthFacade,
                private homeFacade: HomeFacade,
                private ngZone: NgZone,
                private configService: ConfigService,
                private userSettingsFacade: UserSettingsFacade,
                private platformConfigFacade: PlatformConfigFacade,
                private zohoService: ZohoService,
                private clarityService: ClarityService,
    ) {
        window.addEventListener('message', this.receiveMessage.bind(this), false);
        this.setPlatformTheme();
        this.platformConfigFacade.setPlatformConfig();
        this.authFacade.setToken(false);
        this.setUserDetails();
        if( this.env.production) {
            this.integrateClarity();
        }
    }

    ngOnInit() {
        this.isLoggedInAsPlatformAdmin$ = this.homeFacade.selectIsLoggedInByPlatformAdmin$.pipe(
            shareObservable()
        );
        this.userEmail$ = this.authFacade.selectUser$.pipe(
            map((user) => user.email)
        );
        this.integrateZoho();
        this.watchLanguageChange();
        this.registerIcons();
    }

    private integrateZoho(): void {
        if (this.env.zoho_org_id.length > 1 && this.env.zoho_project_id.length > 1) {
            this.zohoService.removeZohoScripts();
            this.zohoSubscription$ = this.authFacade.selectUser$.pipe(
                switchMap((user: User) => {
                    if (user.email) {
                        return this.zohoService.initZohoAsap();
                    } else {
                        this.zohoService.removeZohoScripts();
                        return EMPTY;
                    }
                })
            ).subscribe();
        }
    }

    private integrateClarity(): void {
        this.claritySubscription$ = this.authFacade.selectUser$.pipe(
            tap((user: User) => {
                if (user.email) {
                    this.clarityService.setEmailForClarity(user.email,user.companyId);
                }
            })
        ).subscribe();

    }

    private setUserDetails() {
        this.userStateSubscription$ = this.authService.getToken(false).pipe(
            shareObservable(),
            tap((it) => {
                if (it.claims['email_verified'] == true) {
                    this.authFacade.getMe();
                    this.authFacade.getSubscription();
                }
            })
        ).subscribe();
    }

    private setPlatformTheme() {
        this.userSettingsSubscription$ = this.configService.setTheme().pipe(
            tap(() => {
                this.isLoadingTheme = false;
            }),
            catchError(() => {
                this.isLoadingTheme = false;
                return EMPTY;
            }),
        ).subscribe();
    }

    private receiveMessage(event: MessageEvent) {
        if (event.data.type === 'SIGN_IN') {
            const token = event.data.payload;
            this.authFacade.selectToken$.pipe(
                take(1),
                tap((it) => {
                    if (it) {
                        this.authFacade.signOut();
                    }
                    this.authService.loginWithCustomToken(token).then(() => {
                        window.open(this.clientUrl, '_blank');
                    })

                }),
                tap(() => this.homeFacade.setIsloggedInByPlatformAdmin(true))
            ).subscribe();
        }
    }

    private registerIcons(): void {
        ICON_LIST.forEach(icon => {
            this.iconRegistry.addSvgIcon(
                icon,
                this.sanitiser.bypassSecurityTrustResourceUrl(`${'/assets/icons/'}${icon}.svg`)
            );
        });
    }

    toggleSidebar(): void {
        this.sideBarOpened = !this.sideBarOpened;
        const bodyElement = this.renderer.selectRootElement('body', true);
        if (this.sideBarOpened) {
            this.renderer.addClass(bodyElement, 'overflow-hidden');
        } else {
            this.renderer.removeClass(bodyElement, 'overflow-hidden');
        }
    }

    watchLanguageChange() {
        const lang = window.LANG;
        this.translateService.setDefaultLang(window.LANG);
        this.translateService.use(
            window.LANG
        );
        moment.defineLocale(lang, {
            parentLocale: lang,
            preparse: (it: string) => {
                return it;
            },
            postformat: (it: string) => {
                return it;
            }
        });

        window.addEventListener('languagesChanged', ((event: CustomEvent) => {
            this.ngZone.run(() => {
                this.userSettingsFacade.setLanguages(event.detail);
            });
        }) as EventListener);

        window.addEventListener('languageChanged', () => {
            this.ngZone.run(() => {
                this.translateService.setDefaultLang(window.LANG);
                this.translateService.use(
                    window.LANG
                );
                document.body.removeAttribute('class');
                document.body.classList.add(
                    window.LANG === 'en' ? 'ltr' : 'rtl'
                );
                this.userSettingsFacade.setLanguage(window.LANG);
                moment.locale(window.LANG);
            });
        });
    }

    ngOnDestroy() {
        this.userStateSubscription$.unsubscribe();
        this.userSettingsSubscription$.unsubscribe();
        this.zohoSubscription$.unsubscribe();
        this.claritySubscription$.unsubscribe();
    }
}
