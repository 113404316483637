import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

import { AuthStoreModule } from '@app/shared/store/auth/auth.store.module';
import { SubheaderComponent } from '@app/shared/components/subheader/subheader.component';
import { GeoSnackBarComponent } from '@app/shared/components/snack-bar/snack-bar.component';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { DragDropFileUploadDirective } from '@app/shared/directives/drag-drop-file-upload.directive';
import { GeoSnackBarService } from '@app/shared/services/snack-bar/snack-bar.service';
import { UserService } from '@app/shared/services/user.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PagingComponent } from '@app/shared/components/paging/paging.component';
import { CompanyService } from '@app/shared/services/company.service';
import { BrandService } from '@app/shared/services/brand.service';
import { NumberFormatPipe } from "@app/shared/pipes/number.pipe";
import { LocationService } from "@app/shared/services/location.service";
import { GoogleLocationsComponent } from "@app/shared/components/google-locations/google-locations.component";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DialogHeaderComponent } from '@app/shared/components/dialog-header/dialog-header.component';
import { StarsReviewComponent } from '@app/shared/components/stars-review/stars-review.component';
import { PageSubHeaderComponent } from "@app/shared/components/page-subheader/page-sub-header.component";
import { NumberTranslatePipe } from '@app/shared/pipes/number-translate.pipe';
import { DatetimeTranslatePipe } from '@app/shared/pipes/datetime-translate.pipe';
import { UpperNumberPipe } from '@app/shared/pipes/upper-number.pipe';
import { UserSettingsStoreModule } from "@app/shared/store/user-settings/user-settings.store.module";
import { MatSelectModule } from '@angular/material/select';
import { SubscriptionService } from "@app/shared/services/subscription.service";
import { PaymentService } from "@app/shared/services/payment.service";
import { CoreModule } from "@app/core/core.module";
import { LeaveDialogComponent } from '@app/shared/components/leave-dialog/leave-dialog.component';
import {
    TransifexLanguagePickerComponent
} from "@app/shared/components/transifex-language-picker/transifex-language-picker.component";
import { ReviewParserPipe } from '@app/shared/pipes/review-parser.pipe';
import {
    SelectLocationDialogComponent
} from "@app/shared/components/select-location-dialog/select-location-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { ReviewBoosterService } from '@app/shared/services/review-booster.service';
import { CurrencyNumberTranslatePipe } from '@app/shared/pipes/currency-number-translate.pipe';
import { BrandSelectionComponent } from '@app/shared/components/brand-selection/brand-selection.component';
import { BrandsStoreModule } from "@app/shared/store/brands/brands-store-module";
import { RatingRangePipe } from '@app/shared/pipes/rating.range.pipe';
import { ToDatePipe } from "@app/shared/pipes/todate.pipe";
import { ValueSwitcherComponent } from "@app/shared/components/value-switcher/value-switcher.component";
import { InfoStripComponent } from '@app/shared/components/info-strip/info-strip.component';
import { HealthCheckCardComponent } from "@app/shared/components/health-check-card/health-check-card.component";
import { ZohoService } from "@app/shared/services/zoho.service";
import { ErrorService } from './services/error.service';
import { InputIconToggleComponent } from "@app/shared/components/input-icon-toggle/input-icon-toggle.component";
import { MultiSelectComponent } from "@app/shared/components/multi-select/multi-select.component";
import { PlatformConfigStoreModule } from "@app/shared/store/platform-config/platform-config-store-module";

@NgModule({
  declarations: [
    SubheaderComponent,
    TransifexLanguagePickerComponent,
    GeoSnackBarComponent,
    PagingComponent,
    ConfirmationDialogComponent,
    DragDropFileUploadDirective,
    NumberFormatPipe,
    DialogHeaderComponent,
    GoogleLocationsComponent,
    StarsReviewComponent,
    PageSubHeaderComponent,
    NumberTranslatePipe,
    CurrencyNumberTranslatePipe,
    DatetimeTranslatePipe,
    UpperNumberPipe,
    LeaveDialogComponent,
    ReviewParserPipe,
    SelectLocationDialogComponent,
    BrandSelectionComponent,
    RatingRangePipe,
    ToDatePipe,
    ValueSwitcherComponent,
    InfoStripComponent,
    HealthCheckCardComponent,
    InputIconToggleComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
    AuthStoreModule,
    UserSettingsStoreModule,
    PlatformConfigStoreModule,
    TranslateModule,
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatSelectModule,
    CoreModule,
    ReactiveFormsModule,
    MatInputModule,
    BrandsStoreModule,
    FormsModule,
    MultiSelectComponent
  ],
  providers: [GeoSnackBarService, UserService, CompanyService,
    PaymentService,
    SubscriptionService,
    ZohoService,
    BrandService, LocationService, ReviewBoosterService, ErrorService
  ],


  exports: [
    SubheaderComponent,
    TransifexLanguagePickerComponent,
    PageSubHeaderComponent,
    DragDropFileUploadDirective,
    PagingComponent,
    NumberFormatPipe,
    DialogHeaderComponent,
    GoogleLocationsComponent,
    StarsReviewComponent,
    NumberTranslatePipe,
    CurrencyNumberTranslatePipe,
    DatetimeTranslatePipe,
    UpperNumberPipe,
    ReviewParserPipe,
    BrandSelectionComponent,
    RatingRangePipe,
    ToDatePipe,
    ValueSwitcherComponent,
    InfoStripComponent,
    HealthCheckCardComponent,
    InputIconToggleComponent,
    MultiSelectComponent,
  ],
})
export class SharedModule {
}
