import { select, Store } from "@ngrx/store";
import {
  selectLanguage,
  selectLanguages
} from "@app/shared/store/user-settings/user-settings.selector";
import { setLanguage, setLanguages } from "@app/shared/store/user-settings/user-settings.actions";
import { Injectable } from "@angular/core";
import { UserSettingsState } from "@app/shared/store/user-settings/user-settings.reducer";
import { Language } from "@app/shared/models/transifex";

@Injectable({
  providedIn: 'root',
})
export class UserSettingsFacade {
  constructor(private store: Store<UserSettingsState>) {
  }

  selectLang$ = this.store.pipe(select(selectLanguage));
  selectLanguages$ = this.store.pipe(select(selectLanguages));

  setLanguage(language: string): void {
    this.store.dispatch(setLanguage({language}));
  }
  setLanguages(languages: Language[]): void {
    this.store.dispatch(setLanguages({languages}))
  }

}
