<div class="dialog-header">
  <div class="title d-flex">
    <div *ngIf="icon" class="square-icon">
      <mat-icon [svgIcon]="icon"></mat-icon>
    </div>
    <ng-container *ngIf="!icon">
      <mat-icon class="status "
                *ngIf="status === LocationCardStatus.PENDING"
                svgIcon="hourglass">
      </mat-icon>
      <mat-icon class="success"
                *ngIf="status === LocationCardStatus.SUCCESS"
                svgIcon="done">
      </mat-icon>
      <mat-icon class="warning"
                *ngIf="status === LocationCardStatus.WARNING"
                svgIcon="info">
      </mat-icon>
    </ng-container>
    <ng-container *ngIf="locationMode else nonLocationMode">
      <div>
        <h3 *ngIf="title">{{ title }}</h3>
        <p>
          <span class="notranslate">{{ location?.name +' - '+ location?.storeCode+' - '+location?.state+ ', '+countryName }}</span>
          <span>{{' '}}</span>
          <span class="chips verify" *ngIf="location?.verificationStatus === 'VERIFIED'">
            Verified
          </span>
          <span class="chips review" *ngIf="location?.verificationStatus === 'UNDER_REVIEW'">
            Under Review
          </span>
          <span class="chips review" *ngIf="location?.verificationStatus === 'IN_PROGRESS'">
            Verification in Progress
          </span>
          <span class="chips blocked" *ngIf="location?.verificationStatus === 'DISABLED'">
            Disabled
          </span>
          <span class="chips blocked" *ngIf="location?.verificationStatus === 'EXPIRED'">
            Integration Expired
          </span>
          <span class="chips blocked" *ngIf="location?.verificationStatus === 'SUSPENDED'">
            Suspended
          </span>
          <span class="chips blocked" *ngIf="location?.verificationStatus === 'CONFLICT'">
            Ownership Conflict
          </span>
          <span class="chips blocked" *ngIf="location?.verificationStatus === 'REQUIRED'">
            Verification Required
          </span>
        </p>
      </div>
    </ng-container>

    <ng-template #nonLocationMode>
      <div>
        <h3 *ngIf="title">{{ title }}</h3>
        <p *ngIf="subTitle" [ngClass]="!isSubtitleTranslatable ? 'notranslate': ''">
          {{ subTitle }}
        </p>
      </div>
    </ng-template>
  </div>
  <button mat-icon-button color="primary" [disabled]="readOnly" [disableRipple]="readOnly"
    (click)="closeDialogClicked()">
    <mat-icon svgIcon="close-icon"></mat-icon>
  </button>
</div>
