import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from "@environments/environment";
import { ZohoApiService } from "@app/shared/services/zoho.api.service";
import { tap } from "rxjs/operators";
import { EMPTY } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ZohoService {

    constructor(private rendererFactory: RendererFactory2,
        private zohoApiService: ZohoApiService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);

    }

    private renderer: Renderer2;
    private mainScript = "mainScript";
    private userInfoScript = "userInfoScript";
    private zohoHtmlElementId = "zohohc-asap-web-helper-main";
    env = JSON.parse(JSON.stringify(environment));
    private projectId = this.env.zoho_project_id;
    private orgId = this.env.zoho_org_id;

    initZohoAsap() {
        if (!document.getElementById(this.mainScript)) {
            return this.zohoApiService.getZohoJwt().pipe(
                tap((token) => {
                    this.setUserInfoForZohoAsap(token);
                    const src = `https://desk.zoho.com/portal/api/web/inapp/${this.projectId}?orgId=${this.orgId}`;
                    const scriptElement = this.renderer.createElement('script');
                    this.renderer.setAttribute(scriptElement, 'src', src);
                    this.renderer.setAttribute(scriptElement, 'id', this.mainScript);
                    scriptElement.defer = true;
                    scriptElement.onload = () => {
                        console.log('Zoho script loaded successfully.');
                    };
                    this.renderer.appendChild(document.body, scriptElement);
                })
            );
        }
        else {
            return EMPTY;
        }
    }

    private setUserInfoForZohoAsap(token: string) {
        localStorage.removeItem('clientInfo');
        localStorage.removeItem('AsapOAuthKey');
        localStorage.removeItem('AsapOAuthExpireTimeSpan');
        localStorage.removeItem('AsapOAuthKeyTime');
        const zohoSettingsScript = this.renderer.createElement('script');
        this.renderer.appendChild(zohoSettingsScript, this.renderer.createText(`
      window.ZohoHCAsapSettings = {
      userInfo: {
            jwtToken : "${token}"
          },
      };
    `));
        this.renderer.setAttribute(zohoSettingsScript, 'id', this.userInfoScript);
        zohoSettingsScript.onload = () => {
            console.log('zohoSettingsScript script loaded successfully.');
        };
        this.renderer.appendChild(document.head, zohoSettingsScript);
    }

    removeZohoScripts() {
        const mainScriptElement = document.getElementById(this.mainScript);
        const userInfoScriptElement = document.getElementById(this.userInfoScript);
        const zohoHtmlElement = document.getElementById(this.zohoHtmlElementId);

        if (mainScriptElement) {
            mainScriptElement.remove();
        }
        if (userInfoScriptElement) {
            userInfoScriptElement.remove();
        }
        if (zohoHtmlElement) {
            zohoHtmlElement.remove();
        }
    }
}
