import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { Observable } from 'rxjs';
import { HttpResult } from '@app/shared/models/http-result';
import { map } from 'rxjs/operators';
import { GeokBiDashboard } from '@app/shared/models/geokBiDashboard';
import { GeoDashboard } from "@app/shared/models/geo-dashboard";

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private dashboardURL = '/dashboards';

  constructor(private http: ApplicationHttpClient) {}

  public getDashboardProvision(id: string, language: string): Observable<GeokBiDashboard> {
    const url = this.dashboardURL + `/${id}/provision?language=${language}`;
    return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
  }
  public getDashboards(): Observable<GeoDashboard[]> {
    const url = this.dashboardURL;
    return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
  }
}
