import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges,} from '@angular/core';
import {Subscription, switchMap} from 'rxjs';
import {
  NavigationAppearance,
  NavigationItem,
  NavigationMode,
  NavigationType,
} from '@app/core/components/navigation/navigation.types';
import {MediaWatcherService} from '@app/shared/services/media-watcher';
import {AuthFacade} from '@app/shared/store/auth/auth.facade';
import {ALL_MEMBERS, ALL_PLANS, UserAccessRolesEnum,} from '@app/shared/models/user/user-access';
import {shareObservable} from '@app/helpers/rxjs-helper';
import {tap} from 'rxjs/operators';
import {DashboardService} from '@app/shared/services/dashboard.service';
import {GeoDashboard} from '@app/shared/models/geo-dashboard';
import {PlanTypeEnum} from '@app/shared/models/payment-result';
import {PlanSubscription} from "@app/shared/models/user/plan-subscription";

@Component({
    selector: 'app-geo-navigation-container',
    templateUrl: './geo-navigation-container.component.html',
    styleUrls: ['./geo-navigation-container.component.scss'],
})
export class GeoNavigationContainerComponent
    implements OnInit, OnChanges, OnDestroy {
    navigationMode: typeof NavigationMode = NavigationMode;
    navigationAppearance: typeof NavigationAppearance = NavigationAppearance;
    navigationItems: NavigationItem[] = [];
    isScreenSmall!: boolean;
    private userRolesSubscription$: Subscription = new Subscription();
    private companySubscription$: Subscription = new Subscription();
    private mediaWatcher$: Subscription = new Subscription();
    userRoles: UserAccessRolesEnum[] = [];
    private dashboardNavItems: NavigationItem[] = [];
    currentPlan = PlanTypeEnum.ESSENTIAL;

    @Input() sideBarOpened!: boolean;
    @Input() isOnBoarding!: boolean;

    constructor(
        private _mediaWatcherService: MediaWatcherService,
        private authFacade: AuthFacade,
        private dashboardService: DashboardService,
    ) {
    }

    ngOnInit(): void {
        this.getCompanySubscription();
        this.userRolesSubscription$ = this.authFacade.selectUserRoles.pipe(
            shareObservable(),
            tap((it: UserAccessRolesEnum[]) => this.userRoles = it),
            switchMap(() => this.authFacade.selectUserCompanyId$),
            switchMap(() => {
                return this.dashboardService.getDashboards();
            }),
            tap((it: GeoDashboard[]) => {
                this.dashboardNavItems = it.map((dashboard) => {
                    return {
                        id: dashboard.id,
                        title: dashboard.name,
                        type: NavigationType.item,
                        link: `/dashboards/${dashboard.id}`,
                        access: ALL_MEMBERS,
                        planAccess: this.currentPlan === PlanTypeEnum.EXPERT ? ALL_PLANS : [dashboard.plan],
                    } as NavigationItem;
                })
                this.setNavigationItemsValues(this.isOnBoarding);
            })
        ).subscribe();


        this.mediaWatcher$ = this._mediaWatcherService.onMediaChange$.subscribe(
            ({matchingAliases}) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            }
        );
    }

    private getCompanySubscription() {
       this.companySubscription$ = this.authFacade.selectCompanySubscription$.pipe(
            tap((companySubscription: PlanSubscription) =>
                this.currentPlan = companySubscription.selectedPlan),
        ).subscribe()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isOnBoarding']) {
            if (
                changes['isOnBoarding'].currentValue !==
                changes['isOnBoarding'].previousValue
            ) {
                this.setNavigationItemsValues(changes['isOnBoarding'].currentValue);
            }
        }

    }

    private getDefaultNavigationItems(): NavigationItem[] {
        const navItems: NavigationItem[] = [
            {
                id: 'getSeen',
                title: 'Get Seen',
                tooltip: 'Get Seen',
                icon: 'eye',
                access: ALL_MEMBERS,
                planAccess: ALL_PLANS,
                children: [
                    {
                        id: 'yourLocations',
                        title: 'Your Locations',
                        type: NavigationType.collapsable,
                        access: ALL_MEMBERS,
                        planAccess: ALL_PLANS,
                        children: [
                            {
                                id: 'allLocations',
                                title: 'All Locations',
                                type: NavigationType.item,
                                link: '/locations/presence-management/all',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'blocked',
                                title: 'Requires Action',
                                type: NavigationType.item,
                                link: '/locations/presence-management/blocked',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'allGood',
                                title: 'Live',
                                type: NavigationType.item,
                                link: '/locations/presence-management/all-good',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                        ],
                    },
                    {
                        id: 'socialPosting',
                        title: 'Your Posts',
                        type: NavigationType.collapsable,
                        access: ALL_MEMBERS,
                        planAccess: ALL_PLANS,
                        children: [
                            {
                                id: 'allPosted',
                                title: 'All Posts',
                                type: NavigationType.item,
                                link: 'social-posting/posts-listing',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'published',
                                title: 'Published',
                                type: NavigationType.item,
                                link: 'social-posting/posts-listing/published',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'draft',
                                title: 'Draft',
                                type: NavigationType.item,
                                link: 'social-posting/posts-listing/draft',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'scheduled',
                                title: 'Scheduled',
                                type: NavigationType.item,
                                link: 'social-posting/posts-listing/scheduled',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                        ],
                    },
                ],
            },
            {
                id: 'getChosen',
                title: 'Get Chosen',
                tooltip: 'Get Chosen',
                icon: 'get-chosen',
                access: ALL_MEMBERS,
                planAccess: ALL_PLANS,
                children: [
                    {
                        id: 'manageReviews',
                        title: 'Review Management',
                        type: NavigationType.collapsable,
                        access: ALL_MEMBERS,
                        planAccess: ALL_PLANS,
                        children: [
                            {
                                id: 'allReviews',
                                title: 'All Reviews',
                                type: NavigationType.item,
                                link: '/reviews/filter/all',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'requireسAction',
                                title: 'Requires Action',
                                type: NavigationType.item,
                                link: '/reviews/filter/requires-action',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'treated',
                                title: 'Treated',
                                type: NavigationType.item,
                                link: '/reviews/filter/treated',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                        ],
                    },
                    {
                        id: 'getReviews',
                        title: 'Get Reviews',
                        type: NavigationType.collapsable,
                        access: ALL_MEMBERS,
                        planAccess: ALL_PLANS,
                        children: [
                            {
                                id: 'getReviews',
                                title: 'Get Reviews By Email',
                                type: NavigationType.item,
                                link: '/reviews/get-reviews',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },{
                                id: 'qrcode',
                                title: 'Get Reviews By QR Code',
                                type: NavigationType.item,
                                link: '/reviews/get-qrcode',
                                access: ALL_MEMBERS,
                                planAccess: ALL_PLANS,
                            },
                        ]
                    },
                ],
            },
            {
                id: 'yourGrowth',
                title: 'Get Growing',
                tooltip: 'Get Growing',
                icon: 'growth',
                access: [UserAccessRolesEnum.COMPANY_ADMIN, UserAccessRolesEnum.BRAND_ADMIN, UserAccessRolesEnum.ACCESS_GROUP_ADMIN, UserAccessRolesEnum.BRAND_SOCIAL_MANAGER, UserAccessRolesEnum.ACCESS_GROUP_SOCIAL_MANAGER],
                planAccess: ALL_PLANS,
                children: [
                    {
                        id: 'advancedAnalytics',
                        title: 'Advanced Analytics',
                        type: NavigationType.collapsable,
                        link: '/advancedAnalytics',
                        access: [UserAccessRolesEnum.COMPANY_ADMIN, UserAccessRolesEnum.BRAND_ADMIN, UserAccessRolesEnum.ACCESS_GROUP_ADMIN, UserAccessRolesEnum.BRAND_SOCIAL_MANAGER, UserAccessRolesEnum.ACCESS_GROUP_SOCIAL_MANAGER],
                        planAccess: ALL_PLANS,
                        children: this.dashboardNavItems,
                    },
                ],
            },
            {
                id: 'adminTools',
                title: 'Admin Tools',
                tooltip: 'Admin Tools',
                icon: 'settings',
                access: ALL_MEMBERS,
                planAccess: ALL_PLANS,
                children: [
                    {
                        id: 'profile',
                        title: 'Profile',
                        type: NavigationType.item,
                        link: '/user-profile',
                        access: ALL_MEMBERS,
                        planAccess: ALL_PLANS,
                    },
                    {
                        id: 'team',
                        title: 'Team',
                        type: NavigationType.item,
                        link: '/team-management/team',
                        access: ALL_MEMBERS,
                        planAccess: ALL_PLANS,
                    },
                    {
                        id: 'company',
                        title: 'Company',
                        type: NavigationType.collapsable,
                        access: [UserAccessRolesEnum.COMPANY_ADMIN, UserAccessRolesEnum.BRAND_ADMIN],
                        planAccess: ALL_PLANS,
                        children: [
                            {
                                id: 'brands',
                                title: 'My Brands',
                                type: NavigationType.item,
                                link: '/brands',
                                access: [UserAccessRolesEnum.COMPANY_ADMIN],
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'responseTemplates',
                                title: 'Response Templates',
                                type: NavigationType.item,
                                link: '/brands/response-templates',
                                access: [UserAccessRolesEnum.COMPANY_ADMIN, UserAccessRolesEnum.BRAND_ADMIN],
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'integrations',
                                title: 'Integrations',
                                type: NavigationType.item,
                                link: '/brands/integrations',
                                access: [UserAccessRolesEnum.COMPANY_ADMIN, UserAccessRolesEnum.BRAND_ADMIN],
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'automaticRules',
                                title: 'Automatic Rule',
                                type: NavigationType.item,
                                link: '/brands/rule',
                                access: [UserAccessRolesEnum.COMPANY_ADMIN, UserAccessRolesEnum.BRAND_ADMIN],
                                planAccess: ALL_PLANS,
                            },
                            {
                                id: 'competitors',
                                title: 'Competitors',
                                type: NavigationType.item,
                                link: '/competitors',
                                access: [UserAccessRolesEnum.COMPANY_ADMIN, UserAccessRolesEnum.BRAND_ADMIN],
                                planAccess: [PlanTypeEnum.EXPERT]

                            },
                        ],
                    },
                    {
                        id: 'subscription',
                        title: 'Subscription',
                        type: NavigationType.item,
                        link: '/subscription',
                        access: [UserAccessRolesEnum.COMPANY_ADMIN],
                        planAccess: ALL_PLANS,
                    },
                    {
                        id: 'tags',
                        title: 'Tags',
                        type: NavigationType.item,
                        link: '/tags',
                        access: [UserAccessRolesEnum.COMPANY_ADMIN],
                        planAccess: ALL_PLANS,

                    },
                    {
                        id: 'accessGroups',
                        title: 'Access Groups',
                        type: NavigationType.item,
                        link: '/team-management/access-groups',
                        access: [UserAccessRolesEnum.COMPANY_ADMIN],
                        planAccess: ALL_PLANS,
                    },
                    {
                        id: 'logOut',
                        title: 'Log Out',
                        type: NavigationType.item,
                        access: ALL_MEMBERS,
                        planAccess: ALL_PLANS,
                        onClick: () => this.signOut(),
                    },
                ],
            },
        ] as NavigationItem[];

        const parentNavItems: NavigationItem[] = navItems.filter((navItem: NavigationItem) => {
            return this.userRoles.some((element: UserAccessRolesEnum) => navItem.access.includes(element) && navItem.planAccess?.includes(this.currentPlan));
        });
        const all: NavigationItem[] = parentNavItems.map((item: NavigationItem) => {
            return this.getItemsThatBelongToTheUserRole(item);
        });
        return all as NavigationItem[];
    }

    getItemsThatBelongToTheUserRole(item: NavigationItem): NavigationItem {
        if (item.children) {
            const childrenWithAccess: NavigationItem[] = item.children.filter((child: NavigationItem) => {
                return this.userRoles.some((element: UserAccessRolesEnum) => child.access.includes(element) && child.planAccess?.includes(this.currentPlan));
            });
            const children: NavigationItem[] = childrenWithAccess.map((item: NavigationItem) => {
                return this.getItemsThatBelongToTheUserRole(item);
            });
            return {...item, children: children} as NavigationItem;
        }
        return item;
    }

    private getOnboardingNavigationItems(): NavigationItem[] {
        return [
            {
                id: 'logOut',
                title: 'Log Out',
                tooltip: 'Log Out',
                icon: 'logout',
                onClick: () => this.signOut(),
            },
        ] as NavigationItem[];
    }


    ngOnDestroy(): void {
        this.mediaWatcher$.unsubscribe();
        this.userRolesSubscription$.unsubscribe();
        this.companySubscription$.unsubscribe();
    }

    private signOut(): void {
        this.authFacade.signOut();
    }

    private setNavigationItemsValues(isOnBoarding: boolean) {
        if (isOnBoarding) {
            this.navigationItems = this.getOnboardingNavigationItems();
        } else {
            this.navigationItems = this.getDefaultNavigationItems();
        }
    }


}
