export const environment = {
  production: true,
  api: "localhost:8080",
  backend: 'https://postbusiness.splonline.com.sa', // test
  transifex_api: '0b78e7a23e644bedb4b510326eead9a7',
  clientUrl: 'https://postbusiness.splonline.com.sa',
  adminUrl: 'https://postbusiness.splonline.com.sa/admin',
  gtm: 'GTM-KKZ6W6WQ',
  zoho_project_id: '702765000044647019',
  zoho_org_id: '773804226',
  sentry: {
    sentryAuthToken: '06bf0743b3eb42708990524d6998d08729d1aaea9c244d88b0e8368d8c423b38',
    sentryDSN: '',
    project: '',
    org: ''
  },
  firebase: {
    projectId: 'geoking-spl-prod',
    appId: '1:187152026039:web:95455b290f2f3931008032',
    storageBucket: 'geoking-spl-prod.appspot.com',
    authDomain: 'geoking-spl-prod.firebaseapp.com',
    messagingSenderId: '187152026039',
    apiKey: 'AIzaSyA_McCnoZCU308tb9jSwU4R7va40q7Bw2A'
  },
};
