<div class="content">
    <button class="close" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <app-page-subheader
            class="col-12"
            icon="building"
            [title]="
    !!data.title ? data.title :
    isMultipleSelection ? 'Select Locations': 'Select Location'"
    ></app-page-subheader>
    <form [formGroup]="form" class="filter-form">
        <app-geo-loader *ngIf="isLoading"></app-geo-loader>
        <mat-form-field class="default">
            <mat-label>Search</mat-label>
            <input matInput formControlName="search"/>
            <mat-icon class="mat-icon-rtl-mirror" matPrefix svgIcon="search"></mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="!data?.brandId">
            <mat-label>Brand</mat-label>
            <mat-select formControlName="brandIds" multiple tx-content="exclude">
                <mat-option [value]="item.id" *ngFor="let item of brands">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Tag</mat-label>
            <mat-select formControlName="tagIds" multiple tx-content="exclude">
                <mat-option [value]="item.id" *ngFor="let item of tags">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button type="button" class="text-button" (click)="clearFilter()">
            Clear Filter
        </button>
    </form>
    <ng-container *ngIf="this.dataSource.data.length === 0 && !isLoading; else hasLocation">
        <h1 class="no-state">There are no locations for given filter</h1>
    </ng-container>
    <div *ngIf="isLoading" class="loading-space"></div>
    <ng-template #hasLocation>
        <ng-container *ngIf="!isLoading">
            <div class="table-wrap">
                <div class="header" *ngIf="showSelectAllLocationBanner">
                    <div class="header-start">
                        <p>
                            You
                            selected {{paging.totalCount === selection.selected.length ? ' all ' : ''}} {{selection.selected.length}}
                            locations,
                            <button class="button-link" type="button" (click)="selectAllLocations()"
                                    *ngIf="paging.totalCount > selection.selected.length">
                                click here to select all other locations
                            </button>
                            <button class="button-link" type="button" (click)="clearAllSelections()"
                                    *ngIf="paging.totalCount === selection.selected.length">
                                click here to clear selections
                            </button>
                        </p>
                    </div>
                </div>
                <mat-table [dataSource]="dataSource">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef>
                            <ng-container *ngIf="!data.inViewMode">
                                <mat-checkbox *ngIf="isMultipleSelection"
                                              color="primary"
                                              (change)="$event ? toggleAllRows() : null"
                                              [checked]="selection.hasValue() && isAllSelected()"
                                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                                >
                                </mat-checkbox>
                            </ng-container>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-checkbox
                                    color="primary"
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? toggleSelection(row.id) : null"
                                    [checked]="selection.isSelected(row.id)"
                                    [disabled]="data.inViewMode"
                            >
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <mat-header-cell *matHeaderCellDef>
                            Location
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="d-flex flex-column" tx-content="exclude">
                                <span>{{ element.name }}</span>
                                <span class="store-code">{{ element.storeCode }}</span>
                                <span class="sub">{{ element.address }}</span>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="verified">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>

                        <mat-cell *matCellDef="let element">
                            <mat-icon class="material-symbols-outlined" *ngIf="element.verified">
                                verified
                            </mat-icon>
                            <span *ngIf="element.verified">
                Verified
              </span>
                            <span *ngIf="!element.verified">
                Not Verified
              </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                            *matRowDef="let row; columns: displayedColumns"
                            [ngClass]="{ select: selection.isSelected(row) }"
                    ></mat-row>
                </mat-table>
                <div class="footer">
          <span class="selection-description">
            <span *ngIf="selection.hasValue()"
                  class="notranslate">{{selection.selected.length | numberTranslate}} </span>
              {{
              selection.hasValue()
                  ? ("location selected")
                  : ""
              }}
          </span>
                    <app-paging *ngIf="!!paging.totalCount" [pageSize]="pageSize" [pageIndex]="paging.pageIndex"
                                [totalCount]="paging.totalCount" (changePage)="handleChangePage($event)"></app-paging>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <button class="full-button"
            *ngIf="!data.inViewMode"
            (click)="submitForm()" [disabled]="!selection.selected.length && data.disableSubmitWhenEmpty">
        {{data.buttonLabel ? data.buttonLabel : "Select"}}
    </button>
</div>
